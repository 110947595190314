<template>
  <div></div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "BindLineLogin - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  async mounted() {
    this.$loading({
      lock: true,
      text: "Binding...",
      spinner: "el-icon-loading"
    });

    let url = await this.$router.resolve({
      name: "BindLineLogin"
    });

    try {
      await lineLoginApis.bind({
        code: this.$route.query.code,
        redirect_uri: window.location.origin + url.href
      });

      await this.bindSuccess();
    } catch (e) {
      await this.bindFailed();
    }

    this.$router.push({
      name: "Login"
    });
  },

  methods: {
    async bindSuccess() {
      await this.$message({
        message: "Bind success",
        type: "success"
      });
    },
    async bindFailed() {
      await this.$message({
        message: "Bind failed",
        type: "error"
      });
    }
  }
};
</script>

<style scoped></style>
