import ApiService from "@/common/api.service";

export default {
  getAuthURL(data) {
    return ApiService.query("line/auth-url", data);
  },
  bind(data) {
    return ApiService.post("line/bind", data);
  },
  login(data) {
    return ApiService.post("line/login", data);
  },
  isBind() {
    return ApiService.post("line/is-bind");
  }
};
